import React from "react";
import { Link } from "gatsby";
import parse from "html-react-parser";

const PostExcerpt = (props) => {
  const domNode = props.domNode;
  const options = JSON.parse(domNode.children[0].data);
  const shortenExcerpt = (excerpt) => {
    excerpt = excerpt.replace(/\[\.\.\.\]$/g, "");
    if (excerpt.length > 150) {
      excerpt = excerpt.slice(0, 150).trim();
    }
    excerpt = excerpt + "…";
    return excerpt;
  };
  if (props.excerpt) {
    const excerpt = props.excerpt.split(">")[1]
      .split("")
      .reverse()
      .join("")
      .split("<")[1]
      .split("")
      .reverse()
      .join("");
    const className = options.textAlign ? "wp-block-post-excerpt has-text-align-"+options.textAlign : "wp-block-post-excerpt";
    const More = () => {
      return (options.moreText && options.moreText.length)
        ? (<Link to={ props.uri } className="wp-block-post-excerpt__more-text" title={ "Lire l'actualité "+props.title }>{ options.moreText }</Link>)
        : (<></>);
    };
    return (<><p className={ className }>{ parse(shortenExcerpt(excerpt), {}) }</p><More/></>);
  }
  return (<></>);
};

export default PostExcerpt;
